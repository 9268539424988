// pages/_error.js
import NextError from "next/error";
import ErrorMessage from "@/components/feedback/ErrorMessage";

function CustomError({ statusCode }) {
	if (statusCode === 404) {
		return <NextError statusCode={statusCode} />;
	}

	return <ErrorMessage />;
}

CustomError.getInitialProps = async ({ res, err }) => {
	const errorInitialProps = await NextError.getInitialProps({ res, err });
	errorInitialProps.hasGetInitialPropsRun = true;

	return errorInitialProps;
};

export default CustomError;
